<template>
  <div>
    <div v-show="isLoading">
      <spin-loader />
    </div>

    <div v-if="!isLoading">
      <b-row>
        <b-col
          cols="12"
        >
          <validation-observer
            v-slot="{ handleSubmit }"
            ref="refFormObserver"
          >
            <b-form>
              <b-card
                no-body
              >
                <b-card-title
                  class="mt-2 ml-1 mb-0"
                >
                  <span
                    class="ml-50 text-primary"
                  >
                    Porcentagens para bloqueio de auditoria
                  </span>
                </b-card-title>
                <hr>
                <b-card-body
                  class="pt-1"
                >
                  <b-row>
                    <b-col
                      class="px-1"
                      cols="3"
                    >
                      <b-form-group
                        label="% máxima acima do estimado para fretes da entrega"
                        label-for="audit_delivery"
                      >
                        <b-input-group
                          append="%"
                        >
                          <cleave
                            id="audit_delivery"
                            v-model="auditPercentages.delivery_percentage"
                            name="audit_delivery"
                            class="form-control"
                            :options="options.percentage"
                            placeholder="0,00"
                            @blur="adjustItemDecimalPlaces('delivery_percentage', 2)"
                          />
                        </b-input-group>
                      </b-form-group>
                    </b-col>
                    <b-col
                      class="px-1"
                      cols="3"
                    >
                      <b-form-group
                        label="% máxima acima do estimado para itens do empenho"
                        label-for="audit_commitment"
                      >
                        <b-input-group
                          append="%"
                        >
                          <cleave
                            id="audit_commitment"
                            v-model="auditPercentages.commitment_percentage"
                            name="audit_commitment"
                            class="form-control"
                            :options="options.percentage"
                            placeholder="0,00"
                            @blur="adjustItemDecimalPlaces('commitment_percentage', 2)"
                          />
                        </b-input-group>
                      </b-form-group>
                    </b-col>
                    <b-col
                      class="px-1"
                      cols="3"
                    >
                      <b-form-group
                        label="% mínima de margem para itens do empenho"
                        label-for="audit_commitment_margin"
                      >
                        <b-input-group
                          append="%"
                        >
                          <cleave
                            id="audit_commitment_margin"
                            v-model="auditPercentages.commitment_margin_percentage"
                            name="audit_commitment_margin"
                            class="form-control"
                            :options="options.percentage"
                            placeholder="0,00"
                            @blur="adjustItemDecimalPlaces('commitment_percentage', 2)"
                          />
                        </b-input-group>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-card-body>
              </b-card>
              <b-row
                class="mt-2"
              >
                <b-col
                  cols="12"
                  class="d-flex justify-content-end px-0"
                >
                  <b-button
                    id="audit_save"
                    variant="primary"
                    class="mb-1 mb-sm-0 mr-0 mr-sm-1 actionBtn"
                    type="button"
                    @click="handleSubmit(updatePercentages)"
                  >
                    Salvar
                  </b-button>
                  <b-button
                    id="audit_cancel"
                    type="button"
                    variant="outline-primary"
                    class="mb-1 mb-sm-0 mr-0 mr-sm-1 actionBtn"
                    @click="cancelValues"
                  >
                    Cancelar
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BCardTitle,
  BCardBody,
  BForm,
  BFormGroup,
  BInputGroup,
  BRow,
  BCol,
  BButton,
} from 'bootstrap-vue'
import {
  ValidationObserver,
} from 'vee-validate'

import Cleave from 'vue-cleave-component'
import SpinLoader from '@/components/spinloader/SpinLoader.vue'
import syslic from '@/syslic'

export default {
  components: {
    BCard,
    BCardTitle,
    BCardBody,
    BForm,
    BFormGroup,
    BInputGroup,
    BRow,
    BCol,
    BButton,
    SpinLoader,
    Cleave,
    ValidationObserver,
  },
  data() {
    const isLoading = true

    const auditPercentages = {
      id: '',
      delivery_percentage: '',
      commitment_percentage: '',
    }

    return {
      isLoading,
      auditPercentages,
    }
  },
  created() {
    this.fetchPercentages()
  },
  methods: {
    fetchPercentages() {
      syslic
        .order
        .audit
        .fetchPercentages()
        .then(response => {
          this.replaceCommas(response.data)
          this.auditPercentages = JSON.parse(JSON.stringify(response.data))
          this.percentagesCache = JSON.parse(JSON.stringify(response.data))
        })
        .catch(() => {
          this.$swal({
            title: this.swalConfig.errorTitle,
            text: this.swalConfig.loadTextError,
            icon: this.swalConfig.errorIcon,
            showConfirmButton: true,
            confirmButtonText: this.swalConfig.btnText,
            confirmButtonColor: this.swalConfig.btnColor,
            timer: 8000,
          })
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    updatePercentages() {
      Object.entries(this.auditPercentages).forEach(([key, value]) => {
        if (value === '') {
          this.auditPercentages[key] = '0.00'
        }
      })
      syslic
        .order
        .audit
        .updatePercentages(this.auditPercentages)
        .then(response => {
          this.percentagesCache = JSON.parse(JSON.stringify(response.data))

          this.$swal({
            title: this.swalConfig.successTitle,
            icon: this.swalConfig.successIcon,
            showConfirmButton: true,
            confirmButtonText: this.swalConfig.btnText,
            confirmButtonColor: this.swalConfig.btnColor,
            timer: 8000,
          })
        })
        .catch(() => {
          this.$swal({
            title: this.swalConfig.errorTitle,
            text: this.swalConfig.updateTextError,
            icon: this.swalConfig.errorIcon,
            showConfirmButton: true,
            confirmButtonText: this.swalConfig.btnText,
            confirmButtonColor: this.swalConfig.btnColor,
            timer: 8000,
          })
        })
    },
    cancelValues() {
      this.auditPercentages = JSON.parse(JSON.stringify(this.percentagesCache))
    },
    replaceCommas(object) {
      /* eslint-disable no-param-reassign */
      Object.entries(object).forEach(([key, value]) => {
        if (key !== 'id') {
          object[key] = value.replace('.', ',')
        }
      })
      /* eslint-enable no-param-reassign */
    },
    adjustItemDecimalPlaces(field, places) {
      const decimalReference = 10 ** places
      const num = this.auditPercentages[field]

      if (num !== '') {
        const result = ((num * decimalReference) / decimalReference).toFixed(places)
        this.auditPercentages[field] = result
      }
    },
  },
  setup() {
    const swalConfig = {
      successTitle: 'Porcentagens salvas com sucesso!',
      successIcon: 'success',
      errorTitle: 'Erro!',
      errorIcon: 'error',
      loadTextError: 'Falha ao carregar as porcentagens, por favor entre em contato com o administrador do sistema.',
      updateTextError: 'Falha ao atualizar as porcentagens, por favor entre em contato com o administrador do sistema.',
      btnColor: '#005C96',
      btnText: 'Fechar',
    }

    const options = {
      percentage: {
        numeral: true,
        numeralDecimalScale: 2,
        numeralDecimalMark: ',',
        delimiter: '.',
        numeralPositiveOnly: true,
      },
    }

    return {
      swalConfig,
      options,
    }
  },
}
</script>

<style scoped>
.actionBtn {
  min-width: 8rem;
}

</style>
